import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { SEO } from 'src/components/SEO';
import { Layout } from 'src/components/Layout';
import { PageTitle } from 'src/components/PageTitle';
import { BlueBox } from 'src/components/BlueBox';

const Recruiter = () => (
  <Layout className="recruitment">
    <SEO title="Recruiter" />
    <div className="container pt-2">
      <PageTitle className="mb-2 mb-lg-8" title='Recruiter' />
      <p className="mb-4 text-justify">
        We are always looking for new colleagues to join our Alviso team. For all
        our amazing clients we need to have a strong team of Recruitment consultants
        who cooperate very closely with them. As a recruiter you need to know your
        clients be it companies or candidates really well and understand their challenges,
        priorities and goals. Through that and long term relationships focus you deliver
        a WIN WIN solutions to both clients and candidates.
      </p>
      <h2 className="mb-3">How do you know you want to become Alviso recruiter?</h2>
      <div className="row mb-lg-4 justify-content-xl-between">
        <div className="d-none d-xl-block col-6 col-lg-4 col-xl-2">
          <StaticImage
            src="../assets/images/recruitment/showing.png"
            alt="Graphic"
            layout="fullWidth"
          />
        </div>
        <BlueBox
          className="px-xl-0"
          text={<span>You understand the <strong>whole picture</strong><br /> not just JD and keywords</span>}
        />
        <BlueBox
          className="px-xl-0"
          text={<span>You enjoy <strong>communication<br /> & consulting</strong></span>}
        />
        <BlueBox
          className="px-xl-0"
          text={<span>You love <strong>demanding job</strong> (easy ones do not come to Alviso)</span>}
        />
        <BlueBox
          className="px-xl-0"
          text={<span>You want to build <strong>relationship<br /> with top clients</strong></span>}
        />
      </div>
    </div>
    <div className="container pb-4 pb-lg-9">
      <PageTitle className="mb-4 mb-lg-6" title='How we work' />
      <div className="row">
        <div className="col-12 col-md-6 mb-4">
          <div className="white-box d-flex flex-column">
            <StaticImage
              className="mx-auto mb-3"
              src="../assets/images/recruiter/step1.png"
              alt="Graphic"
            />
            <h2 className="mb-3 text-center">Step 1 - understanding of client's needs</h2>
            <ul className="white-box__list">
              <li>
                You will closely cooperate with the hiring manager, current team into which
                you recruit and the HR team
              </li>
              <li>
                You need to get a full picture of the job as well as the project/product the
                new colleague will focus on
              </li>
              <li>
                You will understand the use of all of the latest technologies, learn about
                their benefits and understand how they work together in a given company.
                Words such as AI, Cybersecurity, Fullstack, Lambda are your daily vocabulary
              </li>
            </ul>
          </div>
        </div>
        <div className="col-12 col-md-6 mb-4">
          <div className="white-box d-flex flex-column">
            <StaticImage
              className="mx-auto mb-3"
              src="../assets/images/recruiter/step2.png"
              alt="Graphic"
            />
            <h2 className="mb-3 text-center">Step 2 - talent scouting</h2>
            <ul className="white-box__list">
              <li>You will search for the most suitable candidates, using all available
                channels; not only all social media platforms and databases, but also you
                will have to leave your comfort zone and take active part in various
                meet-ups and events
              </li>
              <li>
                You will prepare long lists, short lists as well as more complex market
                intelligence reports. You will validate these with hiring managers and
                client´s HR teams
              </li>
              <li>
                You will be constantly on a call or interview working with candidates
                on their career goals and passions. Together you will form a career
                strategy they can follow
              </li>
            </ul>
          </div>
        </div>
        <div className="col-12 col-md-6 mb-4">
          <div className="white-box d-flex flex-column">
            <StaticImage
              className="mx-auto mb-3"
              src="../assets/images/recruiter/step3.png"
              alt="Graphic"
            />
            <h2 className="mb-3 text-center">Step 3 - offer management consulting</h2>
            <ul className="white-box__list">
              <li>Project presented only to short listed candidates</li>
              <li>
                With utmost personal approach we convert maximum number
                of short listed candidates into interviews
              </li>
              <li>
                Our recruiter participates in all interviews and provides
                candidates with guidance and yourself with feedback
              </li>
            </ul>
          </div>
        </div>
        <div className="col-12 col-md-6 mb-4">
          <div className="white-box d-flex flex-column">
            <StaticImage
              className="mx-auto mb-3"
              src="../assets/images/recruiter/step4.png"
              alt="Graphic"
            />
            <h2 className="mb-3 text-center">Step 4 - Stay in touch</h2>
            <ul className="white-box__list">
              <li>
                You will be in touch with the candidate prior to the contract
                signature, prior the first day at work, before the end of the
                trial period, after the end of the trial period
              </li>
              <li>
                You will nurture your network of candidate contacts with birthday
                wishes, work anniversaries, etc.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <p>
        If you are interested and you want to try it, or you are already trying
        elsewhere and you want to try it with us, feel free to get in touch with
        Hanka at her email <a href="mailto:hana.kopecka@alviso.cz">hana.kopecka@alviso.cz </a>
        or phone <a href="tel:00420605475291">+420 605 475 291</a>.
      </p>
    </div>
  </Layout>
);

export default Recruiter;
